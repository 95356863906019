import { useTranslate } from '@tolgee/react';
import Container from '@amf/shared/components/layout/Container';
import IconAppStore from '@amf/shared/icons/IconAppStore';
import IconGooglePlay from '@amf/shared/icons/IconGooglePlay';
import { PartnerFragment } from '@amf/shared/types/graphql';
import FooterPartners from '@amf/shared/components/layout/FooterPartners';

type Props = {
  partners: PartnerFragment[];
};

export default function Footer({ partners }: Props) {
  const { t } = useTranslate();
  return (
    <div className='Footer'>
      <Container>
        <FooterPartners partners={partners} />
        <div className='Footer__row'>
          <div className='Footer__column'>
            <h2>{t('footer.billing.title')}</h2>
            <address>
              Malá kopaná Jihlava zapsaný spolek
              <br />
              Palackého 50
              <br />
              586 01 Jihlava
            </address>
            <p>IČ: 48460729</p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.contact.title')}</h2>
            <address>
              Malá kopaná Jihlava zapsaný spolek
              <br />
              Palackého 50, 586 01 Jihlava
            </address>
            <p>
              <a href='mailto:info@mfjihlava.cz'>info@mfjihlava.cz</a>
              <br />
              <a href='tel:+420728063835'>+420 728 063 835</a>
            </p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.social.title')}</h2>
            <p>
              <a href='https://www.facebook.com/MalaKopanaJihlava' target='_blank' rel='noreferrer'>
                Facebook
              </a>
              <br />
              <a href='https://www.instagram.com/mkjihlava/' target='_blank' rel='noreferrer'>
                Instagram
              </a>
              <br />
              <a
                href='https://www.youtube.com/channel/UCe9yChaeUenVL6NtK1_Jf_A'
                target='_blank'
                rel='noreferrer'
              >
                Youtube
              </a>
            </p>
          </div>
        </div>
        <div className='Footer__apps'>
          <h2>{t('footer.apps.title')}</h2>
          <div className='Footer__appsGrid'>
            <a
              href='https://apps.apple.com/vn/app/mal%C3%BD-fotbal/id1665660869?uo=2'
              target='_blank'
              rel='noreferrer'
            >
              <IconAppStore />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.amf&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <IconGooglePlay />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
